<template>
  <!-- eslint-disable -->
  <v-dialog v-model="dialog" scrollable persistent max-width="500px" content-class="auth-dialog-wrapper">
    <template #default>
      <div class="auth-dialog">
        <div class="auth-dialog-header">
          <!-- header-logo -->
          <div class="header-logo-container">
            <a href="/">
              <img src="../../../public/site_images/logo_honely.png" class="header-logo" alt="Honely" />
            </a>
            <button class="btn-close" @click="hideDialog()">
              <i class="fa fa-times" style="color: black;" />
            </button>
          </div>
          <!-- header-logo -->
        </div>
        <div class="auth-dialog-content">
          <v-form v-model="valid" :lazy-validation="true" ref="form">
            <p class="search-text-helper">Enter a email to send report</p>
            <v-text-field outlined shaped dense label="" placeholder="Email" class="auth-modal-form-control"
              @keyup.enter="onSubmit" :rules="requiredRules" :maxlength="50" :readonly="loading" v-model="email">
            </v-text-field>
            <span v-if="errTxt" class="error--text" style="margin-bottom: 15px;">{{ errTxt }}</span>
            <span v-if="successTxt" class="success--text" style="margin-bottom: 15px;">{{ successTxt }}</span>
            <div v-if="!loading" class="d-flex justify-center mt-5">
              <v-btn rounded color="success" class="btn-submit" :disabled="loading" @click="onSubmit()">
                {{ loading ? 'Please wait...' : 'Send Report' }}
              </v-btn>
            </div>
            <div v-if="loading" class="d-flex justify-center mt-5">
              <v-btn rounded color="success" class="btn-submit" :disabled="loading" @click="onSubmit()">
                {{ loading ? 'Please wait...' : 'Send Report' }}
              </v-btn>
              <!-- <circle-spin :loading='true'></circle-spin> -->
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </v-dialog>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
import { REQUIREED_RULE } from '@/utils/validators'
import axios from 'axios'
export default {

  props: {
    show: Boolean,
    cognitoUser: Object,
    property: Object
  },

  data: () => ({
    loading: false,
    email: '',
    requiredRules: [
      REQUIREED_RULE(),
    ],
    valid: false,
    errTxt: '',
    successTxt: ''
  }),

  mounted() {
    this.email = this.cognitoUser?.attributes?.email
  },

  computed: {
    dialog: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('toggleShow', value)
      },
    },
  },

  methods: {
    hideDialog() {
      this.dialog = false
      this.errTxt = ''
      this.successTxt = ''
    },
    onSubmit() {
      this.errTxt = ''
      this.successTxt = ''
      // console.log({
      //   ['property-id']: this.property?.address?.property_id,
      //   email: this.email
      // }, {
      //   headers: {
      //     Authorization: 'Bearer ' + this.cognitoUser?.signInUserSession?.idToken?.jwtToken,
      //   },
      // })
      if (this.$refs.form.validate()) {
        this.loading = true
        fetch('https://api.honely.com/dev/property_share_email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.cognitoUser?.signInUserSession?.idToken?.jwtToken,
          },
          body: JSON.stringify({
            ['property-id']: this.property?.address?.property_id,
            email: this.email
          }),
        }).then((response) => {
          return response.json()
        }).then((data) => {
          if (data?.data?.status === 'succeed') {
            this.successTxt = 'Report sent successfully.'
          } else {
            this.errTxt = 'Something went wrong. Please try again later'
          }
        }).catch((err) => {
          console.log(err)
          this.errTxt = 'Something went wrong. Please try again later'
        }).finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
